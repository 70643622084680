import request from '@/utils/request'
// 社区活动
export function WeGetMySignupActivityPage(query) {
  return request({
    url: 'PBActivity/WeGetMySignupActivityPages',
    method: 'get',
    params: query,
  })
}
export function WeGetMySignupActivityPages(query) {
  return request({
    url: 'PBActivity/WeGetMySignupActivityPage',
    method: 'get',
    params: query,
  })
}
// 社区活动
export function WxGetMySignupActivityPage(query) {
  return request({
    url: 'Activity/WxGetMySignupActivityPage',
    method: 'get',
    params: query,
  })
}
export function WxGetMySignupActivity(query) {
  return request({
    url: 'Activity/WxGetMySignupActivity',
    method: 'get',
    params: query,
  })
}
